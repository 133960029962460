<template>
  <div>
    <b-overlay :show="loading">
      <good-table-column-search
        v-if="items"
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      filterModel: {
        per_page: 15,
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Telefon',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Kod',
          field: 'code',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Дата',
          field: 'created_at',
          isDateTime: true,
        },

      ],
    }
  },
  computed: {
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getPhoneCode',
      // destroyAction: 'resource/destroyUnitOfMeasure',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.items = res.data
        })
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
